import React, { Component } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import axios from 'axios';
import qs from 'querystring';
import Header from '../components/header';
import Footer from '../components/footer.js';
import { Link, withRouter } from "react-router-dom";
import history from '../history';
import './page-donate.scss';

const data = require("../data/data.json");
const currencies = require('../data/currencies.json');
const roundTo = require('round-to');

var config = {
  headers: {
    'Access-Control-Allow-Origin': '*'
  }
};

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      email: "",
      name: "",
      coverCCFee: false
    };

    this.emailUpdate = this.emailUpdate.bind(this);
    this.nameUpdate = this.nameUpdate.bind(this);
  }

  emailUpdate(event) {
    this.setState({email: event.target.value})
  }

  nameUpdate(event) {
    this.setState({name: event.target.value})
  }

  handleSubmit = async (event) => {
    if (this.state.name === "" || this.state.email === "") {
      this.setState({ processing: false });
      alert("Please provide your name and email.")
      event.preventDefault();
      return;
    }
    this.setState({ processing: true });
    event.preventDefault();
    const { stripe, elements, amount, cause } = this.props;

    const calculatedAmount = amount * 100;

    const requestBody = {
      amount: calculatedAmount,
      currency: "USD",
      customerCoveringFee: false,
      matchRequested: false,
      subaccountNumber: data.employeeAccountNumber,
      persistPaymentMethod: false,
      metadata: [
            {
              key: "email",
              value: this.state.email
            },
            {
              key: "name",
              value: this.state.name
            }
      ]
    }

    const paymentIntent = await axios.post(`${process.env.REACT_APP_GIVINGA_PAYMENTS_URL}/customers/payment-intents`, requestBody, config)
    .catch(error => {
        this.setState({ processing: false });
        alert("Something went wrong while processing your payment");
        return;
    });

    if (paymentIntent !== undefined) {
      const result = await stripe.confirmCardPayment(paymentIntent.data.clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        }
      });

      if (result.error) {
        alert(result.error.message);
        this.setState({ processing: false });
      } else {
        if (result.paymentIntent.status === 'succeeded') {
          history.push({
            pathname: '/donation-thank-you',
            state: { amount: calculatedAmount / 100 }
          });
        }
      }
    } else {
      // We hit an error above.
      this.setState({ processing: false });
    }
  };

  render() {
    const {stripe} = this.props;
    return (
      <div>
      <Header/>
      <center>
      <div className="header">Donate <span style={{color:"#29615b"}}>${this.props.amount}</span> to the <span style={{color:"#29615b"}}>Tomorrow's Climate Fund</span></div>
      </center>
        <div className="donate-page-container">
          <div className="donate-page-container-title">
            <center>
            <form onSubmit={this.handleSubmit}>
            <div className="checkout-form">
              <input placeholder="Name*" value={this.state.name} onChange={this.nameUpdate}/>
              <input placeholder="Email*" value={this.state.email} onChange={this.emailUpdate}/>
            </div>
            <hr/>
            <CardElement />
            <button type="submit" disabled={this.state.processing}>{this.state.processing ? "Processing..." : "Donate Now"}</button>
            </form>
            </center>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}

class InjectedCheckoutForm extends Component {
  render() {
    return (
      <ElementsConsumer>
        {({stripe, elements}) => (
          <CheckoutForm stripe={stripe} elements={elements} amount={this.props.amount} />
        )}
      </ElementsConsumer>
    );
  }
}

async function getStripe() {

  let accountConfig = {
    'Access-Control-Allow-Origin': '*'
  };

  const secrets = await axios.get(`${process.env.REACT_APP_COVID_API}/stripe-auth?accountId=${data.accountNumber}`, data.accountNumber)
  .catch(error => {
    alert("Failed to authenticate Stripe.")
      return;
  });

  config.headers['Authorization'] = `Bearer ${secrets.data.token}`
  return loadStripe(secrets.data.publicKey);
}

class DonateForm extends Component {
  render() {
    return (
      <Elements stripe={getStripe()}>
        <InjectedCheckoutForm amount={this.props.location.state.amount} />
      </Elements>
    );
  }
}

export default withRouter(DonateForm);
