import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header';
import Footer from '../components/footer.js';
import SocialComponent from '../components/social-component';
import { withRouter } from 'react-router-dom';
import './page-thank-you.scss';
import hearts from '../images/thank-you-hearts.png';
const data = require('../data/data.json');

class ThankYouPage extends React.Component {
	print(event) {
		window.print();
	}

	render() {
		const { amount } = this.props.location.state;
		return (
			<div>
				<Header />
				<div className='container-page-thank-you'>
					<div className='container-page-thank-you-title'>
						<center>
							<img className='img-container' src={hearts}></img>
							<h1>Thank you for donating!</h1>
						</center>
					</div>
				</div>
				<div className='receipt'>
					<div className='receipt-title'>Your Donation Receipt</div>
					<div className='receipt-text'>
						Your donation of{' '}
						<span style={{ fontFamily: 'Ubuntu-Semibold' }}>${amount}</span>{' '}
						benefits{' '}
						<span style={{ fontFamily: 'Ubuntu-Semibold' }}>
							Tomorrow’s Climate Fund
						</span>
						.<br />
						<br />
						No goods or services were provided in exchange for these
						contributions. EarthShare is an exempt organization as described in
						Section 501(c)(3) of the Internal Revenue Code with an EIN #
						52-1601960.
						<br />
						<br />
						By making this donation, you are making a contribution to a
						donor-advised fund at EarthShare. EarthShare is a nonprofit
						501(c)(3) that facilitates the granting of funds to the specific
						nonprofit this donation is being processed for.
						<br />
						<br />
						Understand for tax benefit purposes; all transactions are processed
						by EarthShare; as such, your bank/credit card statements will
						reflect payments being processed by EarthShare.
						<br />
						<br />
						<span style={{ fontFamily: 'Ubuntu-Semibold' }}>
							{' '}
							Please{' '}
							<span
								onClick={this.print}
								style={{
									cursor: 'pointer',
									color: 'rgba(67, 140, 222, 1.0)',
									textDecoration: 'underline',
								}}
							>
								print a copy of this page
							</span>{' '}
							for record keeping and tax purposes.
						</span>
					</div>
				</div>
				<div className='thank-you-social-container'>
					<div className='thank-you-social-container-help'>
						<p>HELP BY SHARING</p>
					</div>
					<div className='thank-you-social-container-icons'>
						<center>
							<div data-network='facebook' className='st-custom-button'>
								<img src={require('../images/social/facebook.png')} />
							</div>
							<div data-network='twitter' className='st-custom-button'>
								<img src={require('../images/social/twitter.png')} />
							</div>
							<div data-network='linkedin' className='st-custom-button'>
								<img src={require('../images/social/linkedin.png')} />
							</div>
							<div data-network='email' className='st-custom-button'>
								<img src={require('../images/social/email.png')} />
							</div>
						</center>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

export default withRouter(ThankYouPage);
