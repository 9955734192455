import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header';
import { withRouter } from 'react-router-dom';
import './page-terms.scss';

class Terms extends React.Component {
  render() {
    return (
      <div>
        <button onClick={this.props.closeModal}>close</button>
        <div className="container-page-terms">
          <div className="container-page-terms-title">
            <center>
            <h1>Terms and Conditions</h1>
            <p>
            By making this donation, you are agreeing:
            <br/>
            <br/>
            Certify that neither you nor anyone else will receive any impermissible benefit (e.g., tuition, membership fees with more than incidental benefits, dues, admission to charitable or other event, goods bought at auction, etc.) from the recommended charitable organization from this donation. Examples of permissible benefits include benefits that are not more than incidental, such as free or discounted admission, free or discounted parking, token logo-bearing key chains, caps, t-shirts or calendars.
            <br/>
            <br/>
            Certify that this donation will not fulfill all or a portion of a pledge to the charitable organization.
            <br/>
            <br/>
            By making this donation, you are making a contribution to a donor-advised fund at the Givinga Foundation. The Givinga Foundation is a nonprofit 501(c)(3) that facilitates the granting of funds to the specific nonprofit this donation is being processed for. All tax receipts, if warranted, will be provided by the Givinga Foundation. All donations made through the Site are final, irrevocable, non-refundable, and are subject to these terms. Please contact the Givinga Foundation by mail at 396 Washington St. Suite 307 Wellesley, MA 02481, or by phone at (339) 200-9064, or email at support@givingafoundation.org with any questions. Understand for tax benefit purposes; all transactions are processed by the Givinga Foundation; as such, your bank/credit card statements will reflect payments being processed by the Givinga Foundation.
            </p>
            </center>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Terms);
